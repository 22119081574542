.eventox-dropdown {
    right: 0;
    left: auto;
}

.eventox-select {
    color: #fcfcfc !important;
    background-color: #272b30 !important;
    border: 1px solid #272b30 !important;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    height: 50px;
    padding: 0px;
}

.error-msg {
    color: red !important;
    margin: 5px 0;
    font-size: 16px;
    text-align: left;
}

.ticket-modal {
    margin-top: 50px;
    padding: 20px;
}

.ticket-modal .modal-content {}

.ticket-modal .modal-header {
    height: 50px;
    padding: 10px;
    background-color: #272b30;
    color: #fcfcfc;
    border-bottom: 1px solid #272b30;
}

.ticket-modal .modal-footer {
    padding: 10px;
    height: 65px;
}

.ticket-modal .modal-footer button {
    margin-right: 5px;
}

.ticket-dropdown {
    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(0px, 37px);
}