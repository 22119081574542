.step-app>.step-steps {
    overflow: hidden;
    margin: 0 auto;
}

.step-app>.step-steps>li {
    display: inline-block;
    text-align: center;
    width: 24%;
    position: relative;
    z-index: 1;
}

.step-app>.step-steps>li:before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 185px;
    height: 2px;
    background-color: #C4CAD2;
    top: 35px;
    left: 85px;
}

.step-app>.step-steps>li:last-child:before {
    background-color: transparent;
}

.step-app>.step-steps>li.done:before {
    background-color: #FDB51C;
}

.step-app>.step-steps>li>a,
.step-app>.step-steps>li>a>span {
    display: block;
}

.step-app>.step-steps>li>a>.number {
    background-color: #dae1e8;
    border: 1px solid #b6c2cf;
    border-radius: 50%;
    color: #454e5b;
    font-size: 18px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    margin: 10px auto;
    position: relative;
    width: 50px;
    z-index: 0;
}

.step-app>.step-steps>li>a>.number::before,
.step-app>.step-steps>li.done>a>.number::after {
    border-radius: inherit;
    box-shadow: 1px 1px 5px #d1d7de;
    height: 36px;
    left: 6px;
    position: absolute;
    top: 6px;
    width: 36px;
    background-color: #fff;
}

.step-app>.step-steps>li>a>.number::before {
    content: "";
    z-index: -1;
}

.step-app>.step-steps>li.done>a>.number::after {
    content: "\f00c";
    z-index: 1;
    font-family: fontawesome;
    line-height: 35px;
}

.step-app>.step-steps>li>a>.step-name {
    color: #47494e;
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
}

.step-app>.step-steps>li.error a {
    color: #fff;
}

.step-app>.step-steps>li.done>a>.number {
    background-color: #FDB51C;
    border-color: #D79A18;
}

.step-app>.step-content {
    border: 1px solid #e5e5e5;
    padding-top: 55px;
    border-top: 0;
}

.step-app>.step-content>.step-tab-panel {
    /* display: none; */
}

.step-app>.step-content>.step-tab-panel.active {
    display: block;
}

.step-app>.step-footer {
    margin-top: 15px;
    margin-bottom: 15px;
}

.step-app>.step-footer>.step-btn {
    padding: 4px 16px;
    color: #333;
    text-decoration: none;
    background: #e5e5e5;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
}