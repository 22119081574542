/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .res-main-logo {
        display: inline-block;
    }
    .res-main-logo img {
        height: 40px;
    }
    .main-logo {
        display: none;
    }
    .create-btn {
        padding: 5px 10px;
        display: none;
    }
    .create-btn span {
        display: none;
    }
    .create-btn i {
        margin-right: 0;
    }
    .navbar-nav .nav-link {
        padding: 12px 25px 12px 20px !important;
        font-size: 1.1em;
    }
    .navbar-nav .nav-link:hover {
        color: #EE3F7C !important;
        background-color: #e8f7f7;
    }
    .offcanvas-header {
        border: 1px solid #efefef;
    }
    .offcanvas-logo img {
        /* width: 50px; */
        height: 50px;
        object-fit: cover;
    }
    .offcanvas-body {
        padding: 0 0 1rem !important;
    }
    .pe_5 {
        padding-right: 0 !important;
    }
    .navbar-nav .dropdown-menu {
        position: static;
        border: 0 !important;
        box-shadow: none !important;
        padding: 0 0 0 20px !important;
    }
    .navbar-nav .dropdown-menu .dropdown-item {
        font-size: 18px;
        padding: 0.6rem 1rem !important;
        color: #000;
    }
    .dropdown-submenu .dropdown-item:hover {
        color: #EE3F7C !important;
        background-color: transparent !important;
    }
    .navbar-nav .dropdown-menu .dropdown-item::before {
        content: '\f068';
        font-family: 'Font Awesome 6 Free';
        font-weight: 600;
        font-size: 16px;
        margin-right: 10px;
    }
    .navbar-nav .dropdown-toggle::after {
        display: inline-block;
        float: right;
        margin-top: 10px;
    }
    .submenu-item:after {
        content: '\f0dd';
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        float: right;
        font-size: 12px;
        padding-right: 10px;
    }
    .offcanvas-top-area {
        display: block;
        padding: 0 0 20px;
        text-align: center;
    }
    .create-bg {
        background-color: #f1f2f3;
        border-radius: 0;
        padding: 30px;
    }
    .dropdown-submenu {
        width: 100%;
    }
    .hero-banner-content h2 {
        font-size: 24px;
        line-height: 34px;
    }
    .hero-banner-content p {
        padding: 0;
    }
    .easy-steps-tab .nav {
        display: block;
        width: 100%;
    }
    .step-link {
        width: 100%;
    }
    .main-title h3 {
        font-size: 24px;
        line-height: 42px;
    }
    .p-80 {
        padding: 64px 0 64px;
    }
    .app-form-sidebar {
        display: none;
    }
    .sign-logo {
        display: block;
    }
    .app-top-right-link {
        display: none;
    }
    .app-top-items {
        display: block;
        width: 100%;
        text-align: center;
    }
    .registration-title {
        text-align: center;
    }
    .event-top-date {
        display: none;
    }
    .event-top-dt {
        margin-left: 0;
    }
    .event-main-title {
        font-size: 24px;
    }
    .event-type-name {
        font-size: 14px;
    }
    .countdown-item {
        padding: 10px 0;
    }
    .user-right-dt {
        margin-top: 40px;
    }
    .tab-link {
        width: 100%;
    }
    .custom-tabs .tab-link.active,
    .tab-tabs .show>.tab-link {
        color: #fff;
        background-color: #EE3F7C;
        border-bottom: 2px solid #EE3F7C;
        border-radius: 3px;
    }
    .custom-tabs .tab-link.active:hover {
        color: #fff;
        background-color: #EE3F7C;
        border-bottom: 2px solid #EE3F7C;
    }
    .custom-tabs .tab-link:hover {
        color: #EE3F7C;
        background-color: #fff;
        border-bottom: 2px solid transparent;
    }
    .card-top {
        display: block;
        width: 100%;
    }
    .card-event-img {
        width: 100%;
        height: 160px;
        margin-bottom: 20px;
        margin-right: 0;
    }
    .event-top .card-event-img {
        width: 100%;
        height: 160px;
        margin-bottom: 20px;
        margin-right: 0;
    }
    .card-bottom-item {
        width: 50%;
    }
    .card-bottom-item {
        display: block;
        padding: 20px;
        text-align: center;
    }
    .card-dt-text {
        margin-left: 0;
    }
    .card-icon i {
        margin-bottom: 15px;
    }
    .my-event-tabs {
        display: block;
    }
    .event-link {
        width: 100%;
        border-bottom: none;
    }
    .my-event-tabs .event-link.active,
    .my-event-tabs .show>.event-link {
        color: #fff !important;
        background-color: #EE3F7C !important;
        border-bottom: none !important;
    }
    .my-event-tabs .event-link.active span,
    .my-event-tabs .show>.event-link span {
        color: #fff !important;
    }
    .my-event-tabs .event-link.active .event-count,
    .my-event-tabs .show>.event-link .event-count {
        color: #fff !important;
        background: #7fdb57 !important;
    }
    .event-link {
        padding: 1.5rem !important;
    }
    .user-follow-card {
        display: block;
    }
    .follow-card-btn {
        margin-left: 77px;
    }
    .add-organisation-card {
        display: block;
        text-align: center;
    }
    .ocard-left {
        display: block;
    }
    .ocard-avatar {
        display: inline-block;
        margin: 0;
    }
    .ocard-name {
        margin-left: 0;
        margin-top: 13px;
        margin-bottom: 13px;
    }
    .rs {
        margin-top: 27px;
    }
    .custom2-tabs .tab-link {
        width: 100%;
        display: block;
        margin: 0 0 5px 0 !important;
    }
    .relative-input .form-control {
        width: 100%;
    }
    .event-list .event-top .dropdown {
        position: absolute;
        right: 26px;
        top: 26px;
    }
    .option-btn {
        color: rgba(255, 255, 255, .7);
    }
    .option-btn:hover {
        color: rgba(255, 255, 255, 1);
        border: 1px solid #fff;
    }
    .contact-list .top .icon-box {
        min-width: 100%;
        margin-bottom: 15px;
    }
    .contact-list .bottom .icon-box {
        min-width: 200px;
        margin-bottom: 30px;
    }
    .rmt_4 {
        margin-top: 5px;
    }
    .tracking-tab .nav-tabs .nav-item {
        margin-bottom: 10px;
        width: 100%;
    }
    .organisation-profile {
        max-width: 100%;
        min-width: 100%;
    }
    .profile-edit-btn {
        right: 20px;
    }
    .upgrade-content .upgrade-premium-center {
        max-width: 100%;
    }
    .upgrade-banner img {
        height: auto;
    }
    .brn-checkbox-radio {
        padding-left: 30px;
    }
    .br-checkbox {
        position: absolute;
        left: 0px;
    }
    .input-content {
        padding-left: 30px;
    }
    .price-ticket-card-head .top-name .icon-box {
        min-width: 100%;
        margin-bottom: 15px;
    }
    .price-ticket-card .price-ticket-card-body .icon-box {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    .price-ticket-card .price-ticket-card-body .icon-box:last-child {
        margin-bottom: 0;
    }
    .sell-calculate-banner-bg {
        max-width: 100%;
    }
    .flex-slide {
        display: block;
    }
    .flex-slide .left {
        flex: 0 1 100%;
    }
    .blog-img-card img {
        width: 100%;
        max-height: 250px;
        -o-object-fit: cover;
        object-fit: cover;
        height: 100%;
    }
    .flex-slide .right {
        padding: 1.5rem;
    }
    .flex-slide .right .blog--title {
        font-size: 18px;
        margin-bottom: 15px;
    }
    .blog-block .right .post-meta span {
        font-size: 14px;
    }
    .blog-block .right p {
        font-size: 14px;
    }
    .prev_title {
        max-width: 100px !important;
    }
    .communities-item {
        height: auto;
    }
    .easy-step {
        height: auto;
    }
    .coming-soon-content h2 {
        font-size: 26px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .coming-soon-content #timer div {
        width: 75px;
        height: 75px;
        font-size: 24px;
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .coming-soon-content {
        padding: 37px 20px;
    }
    .error-404-content p {
        padding: 0 0;
    }
    .calculate-top {
        padding: 5px 20px 20px;
    }
    .blog-img-card img {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}


/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767.98px) {
    .res-main-logo {
        display: inline-block;
    }
    .res-main-logo img {
        height: 40px;
    }
    .main-logo {
        display: none;
    }
    .create-btn {
        padding: 5px 15px;
    }
    .create-btn {
        padding: 5px 10px;
        display: none;
    }
    .create-btn span {
        display: none;
    }
    .create-btn i {
        margin-right: 0;
    }
    .navbar-nav .nav-link {
        padding: 12px 25px 12px 20px !important;
        font-size: 1.1em;
    }
    .navbar-nav .nav-link:hover {
        color: #EE3F7C !important;
        background-color: #e8f7f7;
    }
    .pe_5 {
        padding-right: 0 !important;
    }
    .offcanvas-header {
        border: 1px solid #efefef;
    }
    .offcanvas-logo img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
    .offcanvas-body {
        padding: 0 0 1rem !important;
    }
    .navbar-nav .dropdown-menu {
        position: static;
        border: 0 !important;
        box-shadow: none !important;
        padding: 0 0 0 20px !important;
    }
    .navbar-nav .dropdown-menu .dropdown-item {
        font-size: 18px;
        padding: 0.6rem 1rem !important;
        color: #000;
    }
    .dropdown-submenu .dropdown-item:hover,
    .dropdown-submenu .dropdown-item:focus {
        color: #EE3F7C !important;
        background-color: transparent !important;
    }
    .navbar-nav .dropdown-menu .dropdown-item::before {
        content: '\f068';
        font-family: 'Font Awesome 6 Free';
        font-weight: 600;
        font-size: 16px;
        margin-right: 10px;
    }
    .navbar-nav .dropdown-toggle::after {
        display: inline-block;
        float: right;
        margin-top: 10px;
    }
    .submenu-item:after {
        content: '\f0dd';
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        float: right;
        font-size: 12px;
        padding-right: 10px;
    }
    .offcanvas-top-area {
        display: block;
        padding: 0 0 20px;
        text-align: center;
    }
    .create-bg {
        background-color: #f1f2f3;
        border-radius: 0;
        padding: 30px;
    }
    .dropdown-submenu {
        width: 100%;
    }
    .hero-banner-content h2 {
        font-size: 24px;
        line-height: 34px;
    }
    .hero-banner-content p {
        padding: 0;
    }
    .easy-steps-tab .nav {
        display: block;
        width: 100%;
    }
    .step-link {
        width: 100%;
    }
    .main-title h3 {
        font-size: 24px;
        line-height: 42px;
    }
    .p-80 {
        padding: 64px 0 64px;
    }
    .app-form-sidebar {
        display: none;
    }
    .sign-logo {
        display: block;
    }
    .app-top-right-link {
        display: none;
    }
    .app-top-items {
        display: block;
        width: 100%;
        text-align: center;
    }
    .registration-title {
        text-align: center;
    }
    .contact-list .bottom .icon-box {
        min-width: 270px;
        margin-bottom: 30px;
    }
    .event-top-date {
        display: none;
    }
    .event-top-dt {
        margin-left: 0;
    }
    .event-main-title {
        font-size: 24px;
    }
    .event-type-name {
        font-size: 14px;
    }
    .user-right-dt {
        margin-top: 40px;
    }
    .tab-link {
        width: 100%;
    }
    .custom-tabs .tab-link.active,
    .tab-tabs .show>.tab-link {
        color: #fff;
        background-color: #EE3F7C;
        border-bottom: 2px solid #EE3F7C;
        border-radius: 3px;
    }
    .custom-tabs .tab-link.active:hover {
        color: #fff;
        background-color: #EE3F7C;
        border-bottom: 2px solid #EE3F7C;
    }
    .custom-tabs .tab-link:hover {
        color: #EE3F7C;
        background-color: #fff;
        border-bottom: 2px solid transparent;
    }
    .avatar-img-btn {
        left: 60%;
    }
    .card-top {
        display: block;
        width: 100%;
    }
    .card-event-img {
        width: 100%;
        height: 160px;
        margin-bottom: 20px;
        margin-right: 0;
    }
    .card-bottom-item {
        width: 50%;
    }
    .card-bottom-item {
        display: block;
        padding: 20px;
        text-align: center;
    }
    .card-dt-text {
        margin-left: 0;
    }
    .card-icon i {
        margin-bottom: 15px;
    }
    .custom2-tabs .tab-link {
        display: block;
        margin: 0 5px 5px 0 !important;
    }
    .mt_r4 {
        margin-top: 1.5rem;
    }
    .relative-input .form-control {
        width: 100%;
    }
    .event-top .card-event-img {
        width: 100%;
        height: 160px;
        margin-bottom: 20px;
        margin-right: 0;
    }
    .event-list .event-top .dropdown {
        position: absolute;
        right: 26px;
        top: 26px;
    }
    .option-btn {
        color: #fff;
    }
    .rmt_4 {
        margin-top: 5px;
    }
    .upgrade-banner img {
        height: auto;
    }
    .brn-checkbox-radio {
        padding-left: 30px;
    }
    .br-checkbox {
        position: absolute;
        left: 0px;
    }
    .input-content {
        padding-left: 30px;
    }
    .price-ticket-card-head .top-name .icon-box {
        min-width: 100%;
        margin-bottom: 15px;
    }
    .price-ticket-card .price-ticket-card-body .icon-box {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    .price-ticket-card .price-ticket-card-body .icon-box:last-child {
        margin-bottom: 0;
    }
    .sell-calculate-banner-bg {
        max-width: 100%;
    }
    .flex-slide {
        display: block;
    }
    .flex-slide .left {
        flex: 0 1 100%;
    }
    .blog-img-card img {
        width: 100%;
        max-height: 250px;
        -o-object-fit: cover;
        object-fit: cover;
        height: 100%;
    }
    .flex-slide .right {
        padding: 1.5rem;
    }
    .flex-slide .right .blog--title {
        font-size: 18px;
        margin-bottom: 15px;
    }
    .blog-block .right .post-meta span {
        font-size: 14px;
    }
    .blog-block .right p {
        font-size: 14px;
    }
    .prev_title {
        max-width: 100px !important;
    }
    .communities-item {
        height: auto;
    }
    .easy-step {
        height: auto;
    }
    .coming-soon-content h2 {
        font-size: 26px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .coming-soon-content #timer div {
        width: 75px;
        height: 75px;
        font-size: 24px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .coming-soon-content {
        padding: 37px 30px;
    }
    .error-404-content p {
        padding: 0 0;
    }
    .calculate-top {
        padding: 5px 20px 20px;
    }
    .blog-img-card img {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) and (max-width: 991.98px) {
    .navbar-nav .nav-link {
        padding: 12px 25px 12px 20px !important;
        font-size: 1.1em;
    }
    .navbar-nav .nav-link:hover {
        color: #EE3F7C !important;
        background-color: #e8f7f7;
    }
    .pe_5 {
        padding-right: 0 !important;
    }
    .offcanvas-header {
        border: 1px solid #efefef;
    }
    .offcanvas-logo img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
    .offcanvas-body {
        padding: 0 0 1rem !important;
    }
    .navbar-nav .dropdown-menu {
        position: static;
        border: 0 !important;
        box-shadow: none !important;
        padding: 0 0 0 20px !important;
    }
    .navbar-nav .dropdown-menu .dropdown-item {
        font-size: 18px;
        padding: 0.6rem 1rem !important;
        color: #000;
    }
    .dropdown-submenu .dropdown-item:hover,
    .dropdown-submenu .dropdown-item:focus {
        color: #EE3F7C !important;
        background-color: transparent !important;
    }
    .navbar-nav .dropdown-menu .dropdown-item::before {
        content: '\f068';
        font-family: 'Font Awesome 6 Free';
        font-weight: 600;
        font-size: 16px;
        margin-right: 10px;
    }
    .navbar-nav .dropdown-toggle::after {
        display: inline-block;
        float: right;
        margin-top: 10px;
    }
    .submenu-item:after {
        content: '\f0dd';
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        float: right;
        font-size: 12px;
        padding-right: 10px;
    }
    .offcanvas-top-area {
        display: block;
        padding: 0 0 20px;
        text-align: center;
    }
    .create-bg {
        background-color: #f1f2f3;
        border-radius: 0;
        padding: 30px;
    }
    .dropdown-submenu {
        width: 100%;
    }
    .hero-banner-content h2 {
        font-size: 30px;
        line-height: 42px;
    }
    .easy-steps-tab .nav {
        width: 100%;
    }
    .step-link {
        width: 50%;
    }
    .step-link:first-child {
        border-left: 1px solid #efefef;
        border-right: 1px solid #efefef;
        border-top: 1px solid #efefef;
    }
    .step-link:last-child {
        border-left: 1px solid #efefef;
        border-right: 1px solid #efefef;
    }
    .step-link:nth-child(2) {
        border-right: 1px solid #efefef;
        border-top: 1px solid #efefef;
    }
    .step-link:nth-child(3) {
        border-left: 1px solid #efefef;
    }
    .feature-step-link:nth-child(4) {
        border-right: 1px solid #efefef;
        border-left: 1px solid #efefef;
    }
    .bg-dark .step-link:first-child {
        border-left: 1px solid #52565b;
        border-right: 1px solid #52565b;
        border-top: 1px solid #52565b;
    }
    .bg-dark .step-link:last-child {
        border-left: 1px solid #52565b;
        border-right: 1px solid #52565b;
    }
    .bg-dark .step-link:nth-child(2) {
        border-right: 1px solid #52565b;
        border-top: 1px solid #52565b;
    }
    .bg-dark .step-link:nth-child(3) {
        border-left: 1px solid #52565b;
    }
    .app-form-sidebar {
        display: none;
    }
    .sign-logo {
        display: block;
    }
    .app-top-right-link {
        display: none;
    }
    .app-top-items {
        display: block;
        width: 100%;
        text-align: center;
    }
    .registration-title {
        text-align: center;
    }
    .user-right-dt {
        margin-top: 40px;
    }
    .avatar-img-btn {
        left: 57%;
    }
    .card-bottom-item {
        width: 50%;
    }
    .card-bottom-item {
        display: block;
        padding: 20px;
        text-align: center;
    }
    .card-dt-text {
        margin-left: 0;
    }
    .card-icon i {
        margin-bottom: 15px;
    }
    .subscription-item {
        height: 205px;
    }
    .brn-checkbox-radio {
        padding-left: 30px;
    }
    .br-checkbox {
        position: absolute;
        left: 0px;
    }
    .input-content {
        padding-left: 30px;
    }
    .sell-calculate-banner-bg {
        max-width: 100%;
    }
    .flex-slide .left {
        flex: 0 1 40%;
    }
    .blog-img-card img {
        width: 100%;
        max-height: 600px;
        -o-object-fit: cover;
        object-fit: cover;
        height: 100%;
    }
    .flex-slide .right {
        padding: 1.5rem;
    }
    .communities-item {
        height: auto;
        display: flex;
        align-items: stretch;
        transition: 300ms;
        position: relative;
    }
    .communities-img {
        flex: 0 1 40%;
        padding: 10px 10px 10px;
    }
    .communities-img img {
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
        height: 100%;
    }
    .communities-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
    }
    .easy-step {
        height: auto;
    }
    .error-404-content p {
        padding: 0 0;
    }
    .kb-card {
        display: block;
        height: 190px;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) and (max-width: 1199.98px) {
    .navbar-expand-lg .offcanvas-footer {
        display: none;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.2rem !important;
        padding-left: 1.2rem !important;
    }
    .create-btn {
        padding: 8px 12px;
        border-radius: 50%;
        height: 40px;
        width: 40px;
    }
    .create-btn span {
        display: none;
    }
    .create-btn i {
        margin-right: 0;
    }
    .app-form-sidebar {
        display: none;
    }
    .sign-logo {
        display: block;
    }
    .new-sign-link {
        display: none;
    }
    .card-bottom-item {
        width: 50%;
    }
    .card-bottom-item {
        display: block;
        padding: 20px;
        text-align: center;
    }
    .card-dt-text {
        margin-left: 0;
    }
    .card-icon i {
        margin-bottom: 15px;
    }
    .subscription-item {
        height: 230px;
    }
    .brn-checkbox-radio {
        padding-left: 30px;
    }
    .br-checkbox {
        position: absolute;
        left: 0px;
    }
    .input-content {
        padding-left: 30px;
    }
    .flex-slide .right {
        padding: 1.5rem;
    }
    .kb-card {
        display: block;
        height: 190px;
    }
}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .navbar-expand-lg .offcanvas-footer {
        display: none;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.2rem !important;
        padding-left: 1.2rem !important;
    }
    .new-sign-link {
        display: none;
    }
    .pe_right {
        padding-right: 150px;
    }
    .ticket-instructions-content {
        padding-left: 55px;
    }
    .kb-card {
        display: block;
        height: 190px;
    }
}


/* desktop view */

@media all and (min-width: 992px) {
    .dropdown-menu li {
        position: relative;
    }
    .dropdown-menu .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }
    .dropdown-menu .submenu-left {
        right: 100%;
        left: auto;
    }
    .dropdown-menu>li:hover>.submenu {
        display: block;
    }
}